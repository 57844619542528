// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc
} from "firebase/firestore";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid, TextField, Button, Card, CardContent, Typography, MenuItem, FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Divider,
  RadioGroup,
  ListItemIcon,
  FormHelperText
} from '@mui/material';
import Iconify from '../../../components/iconify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth, db, storage } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";

const exitOptions = [
  {
    value: 'Point',
    label: 'Point',
  },
  {
    value: 'Price',
    label: 'Price',
  },
];

const exchanges = [
  {
    value: 'Binance',
    label: 'Binance',
  },
  {
    value: 'Bybit',
    label: 'Bybit',
  },
  {
    value: 'Angelone',
    label: 'Angelone',
  }
];

const tradeCategories = [
  {
    value: 'Options',
    label: 'Options',
  }
  // ,
  // {
  //   value: 'CryptoFutures',
  //   label: 'Crypto Futures',
  // },
];

const tradetypes = [
  {
    value: 'Futures',
    label: 'Futures',
  },
  {
    value: 'Spot',
    label: 'Spot',
  },
];

const strikePriceCe = [
  {
    value: 4,
    label: "OTM +4"
  },
  {
    value: 3,
    label: "OTM +3"
  },
  {
    value: 2,
    label: "OTM +2"
  },
  {
    value: 1,
    label: "OTM +1"
  },
  {
    value: 0,
    label: "ATM 0"
  },
  {
    value: -1,
    label: "ITM -1"
  },
  {
    value: -2,
    label: "ITM -2"
  },
  {
    value: -3,
    label: "ITM -3"
  },
  {
    value: -4,
    label: "ITM -4"
  }
]

const strikePricePe = [
  {
    value: 4,
    label: "ITM -4"
  },
  {
    value: 3,
    label: "ITM -3"
  },
  {
    value: 2,
    label: "ITM -2"
  },
  {
    value: 1,
    label: "ITM -1"
  },
  {
    value: 0,
    label: "ATM 0"
  },
  {
    value: 1,
    label: "OTM +1"
  },
  {
    value: 2,
    label: "OTM +2"
  },
  {
    value: 3,
    label: "OTM +3"
  },
  {
    value: 4,
    label: "OTM +4"
  }
]

const lotSizes = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2"
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: "4"
  }
]

const statIcons = [
  { value: 'controlNifty50', label: 'controlNifty50', icon: "/assets/control_icons/nifty_50.png" },
  { value: 'controlCryptoFutures', label: 'controlCryptoFutures', icon: "/assets/control_icons/crypto_futures.png" },
  { value: 'controlGeneric', label: 'controlGeneric', icon: "/assets/control_icons/generic.png" }
];

function CreateStrategyForm() {
  const navigate = useNavigate();
  const { signup, currentUser } = useAuth();
  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    controlName: '',
    controlIcon: "/assets/control_icons/nifty_50.png",
    tradeCategory: 'Options',
    //Entry
    transaction: 'Buy',
    orderType: 'Market',
    optionType: 'CE',
    exitCheckType: 'PREMIUM',
    lot: 0,
    ltp: 0,
    //Exit
    tkp1: 0, //mid sell off if 0 or null
    tkp2: 0,
    sl: 0,
    enableTrailingLoss: "",
    tl: 0, //TL off if 0 or null
    tlTriggerpt: 'TakeProfit1',
    exitOption: 'Point'
  };
  const transactionOptions = ['Buy', 'Sell'];
  const orderOptions = ['Market', 'Limit'];
  const optionTypes = ['CE', 'PE'];
  const booleanTypes = ['Yes', 'No'];
  const checkTypes = ['PREMIUM', 'NIFTY'];
  // const booleanTypes = [{
  //   option: true,
  //   label: "Yes"
  // }, {
  //   option: false,
  //   label: "No"
  // }]
  const validationSchema = Yup.object().shape({
    // exchange: Yup.string().required('Required'),
    // tradetype: Yup.string().required('Required'),
    // statName: Yup.string().required('Required'),
    // coin: Yup.string().required('Required'),
    /*
    exchange: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
      */
  });
  const onSubmit = async (values, props) => {
    // console.log(values)
    // alert(JSON.stringify(values), null, 2);
    try {
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      // if (docSnap.exists() && docSnap.data().exchanges.length !== 0) {
      // let oExchange;
      let sStatId = "";
      let sCollectionName = "";
      // const aExchanges = docSnap.data().exchanges;
      // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < aExchanges.length; i++) {
      //   if (aExchanges[i].exchangeName === values.exchange) {
      //     aExchanges[i].userid = currentUser.uid
      //     oExchange = { ...aExchanges[i] };
      //   }
      // }
      // sStatId = docSnap.id + values.exchange + values.coin;
      // sCollectionName = values.statName + values.exchange + values.coin;
      sCollectionName = "PositionControls"
      let responseStat = await addDoc(collection(db, sCollectionName), {
        controlName: values.controlName,
        controlIcon: values.controlIcon,
        tradeCategory: values.tradeCategory,
        transaction: values.transaction,
        orderType: values.orderType,
        optionType: values.optionType,
        exitCheckType: values.exitCheckType,
        ltp: values.ltp,
        lot: values.lot,
        tkp1: values.tkp1,
        tkp2: values.tkp2,
        sl: values.sl,
        enableTrailingLoss: values.enableTrailingLoss,
        tl: values.tl,
        tlTriggerpt: values.tlTriggerpt,
        isExitBasedPoint: values.exitOption === "Point" ? true : false,
        isExitBasedPrice: values.exitOption === "Price" ? true : false,
        createdBy: currentUser.uid,
        createdTimeStamp: serverTimestamp(),
        lastChangedBy: '',
        lastChangedTimeStamp: serverTimestamp()
      });
      let aStrategies = docSnap.data().positionControls;
      if (aStrategies) {
        aStrategies.push({
          name: values.controlName,
          id: responseStat.id
        })
      } else {
        aStrategies = []
        aStrategies.push({
          name: values.controlName,
          id: responseStat.id
        })
      }
      await updateDoc(docRef, {
        positionControls: aStrategies,
      });
      console.log(responseStat);
      console.log("Document written with ID: ", responseStat.id);
      alert("Successfull" + responseStat.id);
      props.resetForm();
      navigate('/dashboard/mystrategies');
      // } else {
      //   // doc.data() will be undefined in this case
      //   alert('No exchanges linked please add your exchange');
      //   props.resetForm();
      // }
    } catch (error) {
      console.log(error)
      alert("Failed. Please contact support")
    }
  };
  return (
    <div className="App">
      <Grid>
        <Card style={{ maxWidth: 750, padding: '20px 5px', margin: '0 auto' }}>
          <CardContent>
            {/* <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Before creating strategy please make sure to add your crypto exchange to customrapid
            </Typography> */}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {(props) => (
                <Form noValidate>
                  <Typography variant="h6" gutterBottom>
                    General Info
                  </Typography>
                  <Divider sx={{ mb: 3 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        as={TextField}
                        name="controlName"
                        label=" Control Name"
                        type="text"
                        fullWidth
                        width="300px"
                        error={props.errors.controlName && props.touched.controlName}
                        helperText={<ErrorMessage name="controlName" />}
                        // required
                        style={fieldStyle}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={2} lg={2}  >
                      <Field
                        as={TextField}
                        fullWidth
                        id="outlined-select-controlIcon"
                        select
                        label="Control Icon"
                        required
                        value={props.values.controlIcon}
                        style={fieldStyle}
                        name="controlIcon"
                        error={props.errors.controlIcon && props.touched.controlIcon}
                      >
                        {statIcons.map((option) => (
                          <MenuItem key={option.icon} value={option.icon}>
                            <ListItemIcon>
                              <img src={option.icon} alt={option.label} style={{ width: 54, height: 54 }} />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-tradeCategory"
                        select
                        label="Trade Category"
                        defaultValue="Options"
                        helperText="Please select your trade category"
                        required
                        value={props.values.tradeCategory}
                        style={fieldStyle}
                        name="tradeCategory"
                        error={props.errors.tradeCategory && props.touched.tradeCategory}
                      >
                        {tradeCategories.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                  {/* start container 2 */}
                  <Divider sx={{ mt: 3 }} />
                  <Typography variant="h6" gutterBottom>
                    Entry & Exit Condtions
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="Highlight" component="p" marginTop={2} >
                        Entry conditions :
                      </Typography>
                    </Grid>
                    {/* start item transaction */}
                    <Grid item xs={12} md={3} lg={3}>
                      {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                      <Typography variant="body2" color="textSecondary" component="p"
                        sx={{ mt: 1 }} >
                        Transaction :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Field name="transaction" >
                        {({ field }) => (
                          <RadioGroup
                            row
                            aria-label="transaction type"
                            {...field}
                          >
                            {transactionOptions.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                                disabled={option === 'Sell'}  // Disable "Sell" option
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </Field>
                      <ErrorMessage name="transaction" component="div" />
                    </Grid>
                    {/* end */}
                    {/* start order type */}
                    <Grid item xs={12} md={3} lg={3}>
                      {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                      <Typography variant="body2" color="textSecondary" component="p" gutterBottom
                        sx={{ mt: 1 }}>
                        Order Type :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Field name="orderType">
                        {({ field }) => (
                          <RadioGroup
                            row
                            aria-label="order type"
                            {...field}
                          >
                            {orderOptions.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                                disabled={option === 'Limit'}  // Disable "Limit" option
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </Field>
                      <ErrorMessage name="orderType" component="div" />
                    </Grid>
                    {/* end */}
                    {/* start option type */}
                    <Grid item xs={12} md={3} lg={3}>
                      {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                      <Typography variant="body2" color="textSecondary" component="p" gutterBottom
                        sx={{ mt: 1 }}>
                        Option Type :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Field name="optionType">
                        {({ field }) => (
                          <RadioGroup
                            row
                            aria-label="option type"
                            {...field}
                          >
                            {optionTypes.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </Field>
                      <ErrorMessage name="optionType" component="div" />
                    </Grid>
                    {/* end */}
                    {(props.values.optionType === "CE") && (
                      <>
                        <Grid item xs={6} md={6} lg={6} >
                          <Field
                            as={TextField}
                            name="ltp"
                            label="Strike Price"
                            type="number"
                            select
                            defaultValue={0}
                            fullWidth
                            error={props.errors.ltp && props.touched.ltp}
                            helperText={<ErrorMessage name="ltp" />}
                            // required
                            style={fieldStyle}
                          >
                            {strikePriceCe.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    )}

                    {(props.values.optionType === "PE") && (
                      <>
                        <Grid item xs={6} md={6} lg={6} >
                          <Field
                            as={TextField}
                            name="ltp"
                            label="Strike Price"
                            type="number"
                            select
                            defaultValue={0}
                            fullWidth
                            error={props.errors.ltp && props.touched.ltp}
                            helperText={<ErrorMessage name="ltp" />}
                            // required
                            style={fieldStyle}
                          >
                            {strikePricePe.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6} md={6} lg={6}>
                      <Field
                        as={TextField}
                        name="lot"
                        label="Lot Size"
                        type="number"
                        select
                        defaultValue={1}
                        fullWidth
                        error={props.errors.lot && props.touched.lot}
                        helperText={<ErrorMessage name="lot" />}
                        // required
                        style={fieldStyle}
                      >
                        {lotSizes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    {/* start buy CE/PE */}
                    {(props.values.optionType === "CE" || props.values.optionType === "PE") && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="Highlight" component="p" marginTop={2} >
                            Exit conditions :
                          </Typography>
                        </Grid>
                        {/* start check type */}
                        <Grid item xs={12} md={2} lg={2}>
                          {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                          <Typography variant="body2" color="textSecondary" component="p" gutterBottom
                            sx={{ mt: 1 }}>
                            Exit based on :
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Field name="exitCheckType">
                            {({ field }) => (
                              <RadioGroup
                                row
                                aria-label="exit Check Type"
                                {...field}
                              >
                                {checkTypes.map((option) => (
                                  <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                  />
                                ))}
                              </RadioGroup>
                            )}
                          </Field>
                          <ErrorMessage name="exitCheckType" component="div" />
                        </Grid>
                        {/* end */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            id="outlined-select-exitOption"
                            select
                            label="Exit Option"
                            defaultValue="Point"
                            helperText="Please see the instruction down below"
                            required
                            value={props.values.exitOption}
                            style={fieldStyle}
                            name="exitOption"
                            error={props.errors.exitOption && props.touched.exitOption}
                          >
                            {exitOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                          <Field
                            as={TextField}
                            name="tkp1"
                            label="Take Profit 1 (Mid Sell)"
                            type="number"
                            fullWidth
                            error={props.errors.tkp1 && props.touched.tkp1}
                            helperText={<ErrorMessage name="tkp1" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>

                        <Grid item xs={6} md={3} lg={3}>
                          <Field
                            as={TextField}
                            name="tkp2"
                            label="Take Profit 2"
                            type="number"
                            fullWidth
                            error={props.errors.tkp2 && props.touched.tkp2}
                            helperText={<ErrorMessage name="tkp2" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                          <Field
                            as={TextField}
                            name="sl"
                            label="Stop Loss"
                            type="number"
                            fullWidth
                            error={props.errors.sl && props.touched.sl}
                            helperText={<ErrorMessage name="sl" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>
                      </>
                    )}
                    {/* end */}

                    {/* start trailing loss  */}

                    <Grid item xs={12} md={6} lg={6}>
                      {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                      <Typography variant="body2" color="textSecondary" component="p" gutterBottom
                        sx={{ mt: 1 }}>
                        Enable Trailing Loss ( Trigger Point will be Take profit 1 )
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field name="enableTrailingLoss">
                        {({ field }) => (
                          <RadioGroup
                            row
                            aria-label="enable trailing loss"
                            {...field}
                          >
                            {booleanTypes.map((bool) => (
                              <FormControlLabel
                                key={bool}
                                value={bool}
                                control={<Radio />}
                                label={bool}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </Field>
                      <ErrorMessage name="enableTrailingLoss" component="div" />
                    </Grid>
                    {(props.values.enableTrailingLoss === "Yes") && (
                      <>
                        <Grid item xs={12} md={3} lg={3}>
                          <Field
                            as={TextField}
                            name="tl"
                            label="Trailing Loss Step"
                            type="number"
                            fullWidth
                            error={props.errors.tl && props.touched.tl}
                            helperText={<ErrorMessage name="tl" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>
                      </>
                    )}
                    {/* end */}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" startIcon={<Iconify icon="material-symbols:save" />}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  {/* end */}

                  {/* Instructions */}
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                    <Typography variant="h6">Instructions</Typography>
                    <Typography variant="body2" color="textSecondary">
                      1. Exit Option has two values Price or Point. If Price selected then SL, Take Profit etc should be 
                      mentioned based on NIFTY 50/Premium Price. If Point is selected you can mention SL, Take Profit based on Points which will be added or 
                      substracted from NIFTY 50 Price/Premium. <br />
                      2. Ensure that your stop-loss and take-profit values are not zero. If zero, the trade will be immediately closed.<br />
                      3. The trailing loss will be disabled/off if its value is zero.<br />
                      4. Partial sell/Mid sell will be disabled/off if its value is zero.<br />
                    </Typography>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default CreateStrategyForm;
