import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import CreateStrategyPageForm from '../sections/@dashboard/user/CreateStrategyPageForm';

export default function CryptoCreateStrategyPage() {
  return (
    <>
      <Helmet>
        <title> Create Strategy | customrapid </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Create Strategy
          </Typography>
        </Stack>
          <Scrollbar>
            <CreateStrategyPageForm />
          </Scrollbar>
      </Container>
    </>
  );
}
