/* eslint-disable */
import React from 'react'
import "./footer.css";

const Footer = () => {
  return (
      <>
        <div className="footer-top">

<div className="container">

  <div className="row  justify-content-center">
    <div className="col-lg-6">
      <h3>customrapid.com</h3>
      <p>Trade the big moves</p>
    </div>
  </div>

  <div className="row footer-newsletter justify-content-center">
    <div className="col-lg-6">
      <form action="" method="post">
        <input type="email" name="email" placeholder="Enter your Email"/><input type="submit" value="Subscribe"/>
      </form>
    </div>
  </div>

  <div className="social-links">
    <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
    <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
    <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
    <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
    <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
  </div>
  <div className="disclaimer">
  Disclaimer : Futures, stocks and options trading involves substantial risk of loss and is not suitable for every investor or trader. We customrapid.com provides only automation trading tool strategy market place, we are not providing trading buy or sell signal or any type of recommendation or any Investment Advisery. customrapid.com  is not responsible for any losses incurred as a result of using any of our trading strategies. All trading strategies are used at your own risk.

  </div>
  
</div>
</div>

<div className="container footer-bottom clearfix">
<div className="copyright">
  &copy; Copyright <strong><span>customrapid.com</span></strong>. All Rights Reserved
</div>
<div className="credits">
Made in <a href="#">India</a>
      </div>
    </div>   
      
      </>


 
  )
}

export default Footer