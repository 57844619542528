/* eslint-disable */
import React from 'react'
import "./home.css"
import Headerbar from '../components/home/topbar/Headerbar'
import Hero from '../components/home/hero/Hero'
import Services from '../components/home/services/Services'
import About from '../components/home/aboutUs/About'
import Footer from '../components/home/footer/Footer'
import ScrollUp from '../components/home/scrollUp/ScrollUp'
import Navbar from '../components/navbar/Navbar'

const Home = () => {
  return (
  <>
      <Navbar/>
      <Hero></Hero>
      <main id="main">
      <Services></Services>
      <About></About>
      </main>
      <footer id="footer">
        <Footer></Footer>
      </footer> 
      <ScrollUp></ScrollUp> 
  </>
  )
}

export default Home