import React from 'react';
import { Container, Grid, Box, AppBar, Toolbar, styled, Typography } from '@mui/material';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px',
});

export default function Footer() {
  return (
    <AppBar position="static" sx={{backgroundColor:"#F9FAFB", boxShadow:"none",}}>
        <Typography variant='div' sx={{  display:'flex', justifyContent:"center", fontSize:"14px", color:"#212B36", letterSpacing:"0.02857em", lineHeight:"1.5", fontWeight:"600"}}>© 2022, made in India customrapid.com </Typography>
    </AppBar>
  );
}
