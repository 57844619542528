// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  order:PropTypes.object,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  details: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  additionalText: PropTypes.string
};

export default function AppWidgetSummary({path, additionalText, title, total, icon, order, color = 'primary', sx, details, type, ...other }) {

  const handleOrderEdit = (event, order1) => {
    // alert(order1)
    alert("Order Edit not enabled")
  };

  const handleShowLog = (event, order1) => {
    // alert(order1)
    alert("Show Logs not enabled")
  };
  
  return (
    <Card
      sx={{
        // py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <CardContent>
        <StyledIcon
          sx={{
            color: (theme) => theme.palette[color].dark,
            backgroundImage: (theme) =>
              `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                theme.palette[color].dark,
                0.24
              )} 100%)`,
          }}
        >
          <Iconify icon={icon} width={24} height={24} />
        </StyledIcon>

        {(total === 0) && (
                  <>
                  <Typography variant="h3">0</Typography>
                  </> 
                      )}  

                      
        {(total !== 0) && (
                  <>
                  <Typography variant="h3">{fShortenNumber(total)}</Typography>
                  </> 
                      )} 

        

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {details}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {type}
        </Typography>

      </CardContent>
      <CardActions disableSpacing>
      {(additionalText) && (
                  <>
                 {/* <Button size="small" variant="contained" component={Link} to={path}>show {additionalText}</Button> */}
                  </> 
                      )}   
      
        {/* <Button size="small" variant="outlined" onClick={(e) => handleOrderEdit(e, order)} >show {type}</Button> */}
        {/* <Button size="small"  onClick={(e) => handleShowLog(e, order)} >Show Logs</Button> */}
      </CardActions>
    </Card>
  );
}
