import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import HeaderbarAuth from '../components/home/topbar/HeaderbarAuth';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import NavbarAuth from '../components/navbar/NavbarAuth';
// sections
import { LoginForm } from '../sections/auth/login';

import { useAuth } from "../context/AuthContext";

import { auth, db, storage } from "../firebase";


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '87vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');



  return (
    <>

      <Helmet>
        <title> Login | customrapid </title>
      </Helmet>
      <NavbarAuth />
      <StyledRoot>
        {/*
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        */}

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome
            </Typography>
            <img src="/assets/images/login.svg" width="55%" height="55%" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items to the left
                marginTop: '20px',
              }}
            >
              <Typography variant="h4" sx={{ marginRight: -2, marginTop: 1 }}>
                Login to
              </Typography>
              <Box
                component="img"
                src="/assets/icons/navbar/CRlogo_fullcolor.png"
                alt="CustomRapid Logo"
                sx={{
                  height:70, 
                }}
              />
            </Box>
            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Signup</Link>
            </Typography>
            <LoginForm label="Login with Google" />
          </StyledContent>
        </Container>
      </StyledRoot>
      <Footer sx={{ margin: "0 10px 0 0 " }} />
    </>
  );
}
