/* eslint-disable */
// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query, 
  where, 
  getDocs
} from "firebase/firestore";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleButton } from 'react-google-button';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { useAuth } from "../../../context/AuthContext";

import { auth, db, storage } from "../../../firebase";
// ----------------------------------------------------------------------

export default function LoginForm({label}) {
  const { currentUser, login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login();
      /**** 
      if(currentUser.email && currentUser.email !== ""){        
        const q = query(collection(db, "users"), where("email", "==", currentUser.email)); 
        const querySnapshot = await getDocs(q);
        let oDocUser = [];
        querySnapshot.forEach((doc) => {
          oDocUser.push(doc)
  //        console.log(doc.id, " => ", doc.data());
        }); 
        if(oDocUser.length === 0) {
          setError("Account does not exits in BMT please use sign up link");
          /*
          const userRef = await addDoc(collection(db, "users"),{
            email: currentUser.email,
            name: currentUser.displayName,
            phoneNumber:currentUser.phoneNumber,
            photoURL: currentUser.photoURL,
            timeStamp: serverTimestamp(),
            followers: []
          });
          navigate("/dashboard");
        } else if (oDocUser.length === 1) {
          navigate("/dashboard");
        } else {
          setError("Contact support");
        }
      }else{
        setError("Failed to log in");
      }
      ****/
    } catch (error) {
      console.log(error)
      setError("Failed to log in");
    }
    setLoading(false);
  }
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  return (
    <>
      <Stack spacing={3}>
        <GoogleButton label={label} onClick={handleSubmit}/>
      </Stack>
    </>
  );
}
