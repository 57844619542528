/* eslint-disable */
import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const exchanges = [
  {
    value: 'Binance',
    label: 'Binance',
  },
  {
    value: 'Bybit',
    label: 'Bybit',
  }
];

export default function AddExchangeForm() {
  const [exchange, setExchange] = React.useState('');

  const handleChange = (event) => {
    setExchange(event.target.value);
  };

  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
  });
  const onSubmit = (values, props) => {
    alert(JSON.stringify(values), null, 2);
    props.resetForm();
  };
  return (
    <Grid>
      <Paper elevation={0} style={paperStyle}>
        <Grid align="center">
          <Typography variant="caption">Fill the form to link exchange</Typography>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) => (
            <Form noValidate>
              <Field
                as={TextField}
                id="outlined-select-exchange"
                select
                label="Exchange"
                defaultValue="Binance"
                helperText="Please select your exchange"
                required
                onChange={handleChange}
                value={exchange}
                style={fieldStyle}
              >
                {exchanges.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>

              <Field
                as={TextField}
                name="apikey"
                label="API Key"
                type="password"
                fullWidth
                error={props.errors.password && props.touched.password}
                helperText={<ErrorMessage name="apikey" />}
                required
                style={fieldStyle}
              />

              <Field
                as={TextField}
                name="apisecret"
                label="API Secret"
                type="password"
                fullWidth
                error={props.errors.confirmPassword && props.touched.confirmPassword}
                helperText={<ErrorMessage name="apisecret" />}
                required
                style={fieldStyle}
              />
              <Button type="submit" style={btnStyle} variant="contained" color="primary" fullWidth>
                Add
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
}
