/* eslint-disable */
// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query, 
  where, 
  getDocs,
  getDoc
} from "firebase/firestore";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleButton } from 'react-google-button';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { useAuth } from "../../../context/AuthContext";

import { auth, db, storage } from "../../../firebase";
// ----------------------------------------------------------------------

export default function SignUpForm({label}) {
  const { signup, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      //passwordRef.current.children[1].children[0].value
     await signup();
     const docRef = doc(db, 'Customers', currentUser.uid);
     const docSnap = await getDoc(docRef);
     if (docSnap.exists()) {
        alert("Already account exits, please use login")
        navigate("/login");
     } else {
      await setDoc(docRef, {
        name: currentUser.displayName,
        email: currentUser.email,
        mobileNumber:currentUser.phoneNumber,
        photoURL: currentUser.photoURL,
        timeStamp: serverTimestamp(),
        encryptionKey: "",
        exchanges: [],
        strategies: [],
        algos: [],
        tradeEngines:[]
      });
      navigate("/dashboard");
     }

        /*
        const userRef = await addDoc(collection(db, "users"),{
          authId: currentUser.uid, 
          displayName: currentUser.displayName,
          email: currentUser.email,
          phoneNumber:currentUser.phoneNumber,
          photoURL: currentUser.photoURL,
          timeStamp: serverTimestamp(),
          encryptionKey: "",
          exchanges: []
        });
        */
 //     navigate('/authentication/sign-in')
    } catch (error) {
      console.log(error);
      setError("Failed to create an account");
    }
    setLoading(false);
  }
  return (
    <>
      <Stack spacing={3}>
        <GoogleButton label={label} onClick={handleSubmit}/>
      </Stack>
    </>
  );
}
