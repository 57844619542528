import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import CreateStrategyForm from '../sections/@dashboard/user/CreateStrategyForm';

export default function CreateStrategyPage() {
  return (
    <>
      <Helmet>
        <title> Create Control Plan | customrapid </title>
      </Helmet>
      <Container>
      <Box 
          sx={{ 
            height: '50px', 
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
          Create Control
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          {/* <Typography variant="h4" gutterBottom>
          Create Strategy
          </Typography> */}
          <Button variant="contained" component={Link} to="/dashboard/mystrategies" startIcon={<Iconify icon="eva:arrow-left-fill" />}>
            Back
          </Button>
        </Stack>
          <Scrollbar>
            <CreateStrategyForm />
          </Scrollbar>
      </Container>
    </>
  );
}
