/* eslint-disable */
import React from 'react'
import "./scrollUp.css";

const ScrollUp = () => {
  return (
      <>
    <a href="#" className="back-to-top d-flex align-items-center justify-content-center active">
      <i className="bi bi-arrow-up-short"></i>
      </a>
      </>

  )
}

export default ScrollUp